import React, {FC, JSX, useEffect, useState} from "react";
import {User} from "../../interfaces/User";
import {NavigateFunction} from "react-router-dom";
import {login} from "../../api/login";
import {InputText} from "@fattureincloud/fic-design-system";
import validatePassword from "../../utils/validatePassword";
import validateEmail from "../../utils/validateEmail";
import {ButtonNew} from "../../utils/styledComponents/styledComponents";
import {Login, LoginError, LoginTitle} from "./login.style";


interface props {
    setUser: (user: User | null) => void,
    navigate: NavigateFunction,
    user: User | null,
}

const LoginTable: FC<props> = (props: props): JSX.Element => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [wrongEmail, setWrongEmail] = useState<boolean>(false);
    const [wrongPassword, setWrongPassword] = useState<boolean>(false);

    useEffect(() => {
        if (props.user && props.user?.logged) {
            props.navigate('/')
        }
    }, [props.user, props.user?.logged, props])

    return (
        <Login>
            <LoginTitle>Benvenuto!</LoginTitle>
            <InputText id="email"
                       inputType="text"
                       status={error || wrongEmail ? "error" : "normal"}
                       value={email}
                       placeholder="Email"
                       onChange={(e) => {
                           setError(false)
                           setWrongEmail(false)
                           setEmail(e.target.value)
                       }}/>
            <br/>
            <InputText id="password"
                       inputType="password"
                       status={error || wrongPassword ? "error" : "normal"}
                       value={password}
                       placeholder="Password"
                       tooltip={{
                           message: "La password deve essere di almeno 8 caratteri, e contenere almeno un carattere " +
                               "maiuscolo, uno minuscolo, un numero e un carattere speciale",
                           type: "light",
                           placement: "top",
                           showAlways: false
                       }}
                       onChange={(e) => {
                           setError(false)
                           setWrongPassword(false)
                           setPassword(e.target.value)
                       }}/>
            {error ? <LoginError hidden={false}>La mail e/o la password sono errati</LoginError> : <br/>}
            <ButtonNew onClick={() => {
                setWrongPassword(!validatePassword(password))
                setWrongEmail(!validateEmail(email))
                if (validatePassword(password) && validateEmail(email)) {
                    login(email, password, props.setUser, setError);
                }
            }}
                       isDisabled={email === "" && password === ""}
                       text="login"/>
            <br/>
        </Login>)
        ;
}
export default LoginTable;