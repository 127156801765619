import {Theme} from '@fattureincloud/fic-design-system'
import {badgePalette} from './components/badgePalette'
import {bannerPalette} from './components/bannerPalette'
import {buttonPalette} from './components/buttonPalette'
import {checkBoxPalette} from './components/checkBoxPalette'
import {chipPalette} from './components/chipPalette'
import {datePickerPalette} from './components/datePickerPalette'
import {dropdownPalette} from './components/dropdownPalette'
import {fileUploaderPalette} from './components/fileUploaderPalette'
import {floatingBadgePalette} from './components/floatingBadgePalette'
import {iconBackgroundPalette} from './components/iconBackgroudPalette'
import {iconButtonPalette} from './components/iconButtonPalette'
import {iconPalette} from './components/iconPalette'
import {inlineMessagePalette} from './components/inlinemessagePalette'
import {inputHelperPalette} from './components/inputHelperPalette'
import {inputTextPalette} from './components/inputTextPalette'
import {labelPalette} from './components/labelPalette'
import {microTagPalette} from './components/microTagPalette'
import {paginationPalette} from './components/paginationPalette'
import {progressBarPalette} from './components/progressBarPalette'
import {radioPalette} from './components/radioPalette'
import {selectPalette} from './components/selectPalette'
import {sidebarItemPalette} from './components/sidebarItemPalette'
import {stepperPalette} from './components/stepperPalette'
import {tablePalette} from './components/tablePalette'
import {tagPalette} from './components/tagPalette'
import {textAreaPalette} from './components/textAreaPalette'
import {tipPalette} from './components/tipPalette'
import {toastPalette} from './components/toastPalette'
import {toolTipPalette} from './components/toolTipPalette'
import {ficPalette} from './palette'
import {verticalTabsPalette} from './components/verticalTabPalette'
import {segmentButtonPalette} from './components/segmentButtonPalette'
import {cardPalette} from './components/cardPalette'
import {monthlyTabPalette} from './components/monthlyTabPalette'
import {circularProgressBarPalette} from './components/circularProgressBarPalette'
import closableCardPalette from './components/closableCardPalette'

export const theme: Theme = {
  components: {
    badge: badgePalette,
    banner: bannerPalette,
    button: buttonPalette,
    card: cardPalette,
    checkbox: checkBoxPalette,
    chip: chipPalette,
    datePicker: datePickerPalette,
    dropdown: dropdownPalette,
    fileUploader: fileUploaderPalette,
    floatingBadge: floatingBadgePalette,
    icon: iconPalette,
    iconBackground: iconBackgroundPalette,
    iconButton: iconButtonPalette,
    inlineMessage: inlineMessagePalette,
    inputHelper: inputHelperPalette,
    inputText: inputTextPalette,
    label: labelPalette,
    microTag: microTagPalette,
    pagination: paginationPalette,
    progressBar: progressBarPalette,
    radio: radioPalette,
    segmentButton: segmentButtonPalette,
    select: selectPalette,
    sidebarItem: sidebarItemPalette,
    stepper: stepperPalette,
    table: tablePalette,
    tag: tagPalette,
    textArea: textAreaPalette,
    tip: tipPalette,
    toast: toastPalette,
    tooltip: toolTipPalette,
    circularProgressBar: circularProgressBarPalette,
    verticalTabs:verticalTabsPalette ,
    monthlyTab: monthlyTabPalette,
    closableCard: closableCardPalette
  },
  palette: ficPalette,
}
