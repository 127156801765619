import {httpRequest} from "../utils/network";
import {Dispatch, SetStateAction} from "react";
import {User} from "../interfaces/User";
import {Toast} from "@fattureincloud/fic-design-system";

export function login(email: string, password: string, setUser: (user: (User | null)) => void, setError: Dispatch<SetStateAction<boolean>>) {

    httpRequest({
        method: "POST",
        path: "/login",
        params: {email: email, password: password},
        getResponse: (res) => {
            // @ts-ignore
            document.cookie = res.headers["set-cookie"].find((a) => a === "madbit_roadmap_session")
        },
        onSuccess: (data) => {
            setError(false)
            setUser({
                id: data.id,
                username: data.username,
                logged: true,
                isSuperuser: Boolean(data.is_superuser)
            });
            localStorage.clear()
            localStorage.setItem('id', data.id)
            localStorage.setItem('username', data.username)
            localStorage.setItem('logged', "1")
            localStorage.setItem('isSuperuser', data.is_superuser)
            Toast.success("Login avvenuto con successo")
        },
        onError: (response) =>  {
            if(response.status === 422)
                Toast.error(response.data.message)
        }

    })
}

export function logout(setUser: Dispatch<SetStateAction<User | null>>) {
    httpRequest({
        method: "GET",
        path: "/logout",
        onSuccess: (data) => {
            localStorage.clear()
            setUser(null);
            Toast.success("Logout avvenuto con successo")
        },
        onError: () => {
            setUser(null)
            localStorage.clear()
        }
    });
}