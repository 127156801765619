import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {deleteUser, editUser, showUsers} from "../../api/user";
import {UserData} from "../../interfaces/User";
import {Checkbox, InputText} from "@fattureincloud/fic-design-system";
import DialogModal from "../Projects/components/DialogModal";
import Editable from "../../interfaces/Editable";
import CardInsertUser from "./components/CardInsertUser";
import validateEmail from "../../utils/validateEmail";
import NavigationProps from "../../interfaces/NavigationProps";
import {ButtonNewRight, Title} from "../../utils/styledComponents/styledComponents";
import {CardUser, ContainerDiv} from "./components/admin.style";

function UserCard({data, edit, setEdit, setData, navigationProps}: {
    data: UserData,
    edit: Editable,
    setEdit: Dispatch<SetStateAction<Editable>>,
    setData: Dispatch<SetStateAction<UserData[]>>,
    navigationProps: NavigationProps

}) {
    const [username, setUsername] = useState<string>(data.username)
    const [email, setEmail] = useState<string>(data.email)
    const [firstName, setFirstName] = useState<string>(data.first_name)
    const [lastName, setLastName] = useState<string>(data.last_name)
    const [isSuperuser, setIsSuperuser] = useState<boolean>(Boolean(data.is_superuser));
    const [isConfirmOpened, setIsConfirmOpened] = useState(false);
    const [wrongEmail, setWrongEmail] = useState<boolean>(false);


    var usernameRow;
    var emailRow;
    var firstNameRow;
    var lastNameRow;
    var isSuperuserRow;

    if (edit.edit && edit.id === data.id) {
        usernameRow = <div>
            <InputText
                label="Username:"
                placeholder="Username"
                value={username}
                onChange={
                    (event) => {
                        if (event.target.value.length < 50)
                            setUsername(event.target.value)
                    }
                }/>
            <br/>
        </div>;

        isSuperuserRow = <Checkbox
            text={"Superuser"}
            value={isSuperuser}
            onClick={() => {
                setIsSuperuser(!isSuperuser)
            }}
        />
        emailRow = <>
            <InputText
                label="Email:"
                placeholder="Email"
                status={wrongEmail ? "error" : "normal"}
                value={email}
                onChange={(event) => {
                    setWrongEmail(false)
                    if (event.target.value.length < 255)
                        setEmail(event.target.value)
                }}
            />
            <br/>
        </>
        firstNameRow = <>
            <InputText
                label="Nome:"
                placeholder="Nome"
                value={firstName}
                onChange={(event) => {
                    if (event.target.value.length < 30)
                        setFirstName(event.target.value)
                }}
            />
            <br/>
        </>
        lastNameRow = <>
            <InputText
                label="Cognome:"
                value={lastName}
                placeholder="Cognome"
                onChange={(event) => {
                    if (event.target.value.length < 150)
                        setLastName(event.target.value)
                }}
            />
            <br/>
        </>

    } else {

        usernameRow = <p>Username: {data.username}</p>
        isSuperuserRow = <p>{isSuperuser ? "L'utente è un superuser" : "L'utente non è un superuser"}</p>
        emailRow = <p>Email: {data.email}</p>
        firstNameRow = <p>Nome: {data.first_name}</p>
        lastNameRow = <p>Cognome: {data.last_name}</p>
    }

    return (
        <CardUser>
            {
                <>
                    {
                        <>
                            <ButtonNewRight
                                isDisabled={
                                    (email === "" || username === "") && edit.edit && edit.id === data.id
                                }
                                onClick={(event) => {
                                    if (edit.edit && edit.id === data.id) {
                                        setWrongEmail(!validateEmail(email))
                                        if(validateEmail(email)) {
                                            setEdit({edit: false, id: -1})
                                            editUser(data.id, username, firstName, lastName, email, isSuperuser, setData, navigationProps)
                                        }
                                    } else {
                                        setUsername(data.username)
                                        setFirstName(data.first_name)
                                        setLastName(data.last_name)
                                        setEmail(data.email)
                                        setEdit({
                                            edit: true,
                                            id: data.id
                                        })
                                    }
                                }
                                }
                                text={edit.edit && edit.id === data.id ? "Salva" : "Modifica"}
                            />

                            <ButtonNewRight
                                onClick={(event) => {
                                    if (edit.edit && edit.id === data.id) {
                                        setEdit({edit: false, id: -1})
                                    } else {
                                        setIsConfirmOpened(true)
                                    }
                                }
                                }
                                text={edit.edit && edit.id === data.id ? "Annulla" : "Elimina"}
                            />
                        </>


                    }
                    <DialogModal
                        title="CONFERMA"
                        isOpened={isConfirmOpened}
                        onProceed={() => {
                            deleteUser(data.id, setData, navigationProps)
                        }
                        }
                        onClose={() => setIsConfirmOpened(false)}
                    >
                        <p>Sei sicuro di voler procedere?</p>
                    </DialogModal>
                </>

            }
            {usernameRow}
            {emailRow}
            {firstNameRow}
            {lastNameRow}
            {isSuperuserRow}
        </CardUser>
    )
}

export default function Admin(navigationProps:NavigationProps) {
    const [data, setData] = useState<UserData[]>([])
    const [edit, setEdit] = useState<Editable>({edit: false, id: -1})
    useEffect(() => {

        const abortController = new AbortController();
        showUsers(abortController, setData, navigationProps)
    }, [navigationProps])

    const rows = data.map((cardData: UserData) => {
        return <UserCard key={cardData.id} data={cardData} edit={edit} setEdit={setEdit} setData={setData} navigationProps={navigationProps}/>
    })
    return (
        <ContainerDiv>
            <div>
                <Title>Gestione utenti</Title>
            </div>
            <div>
                <CardInsertUser setData={setData} edit={edit} navigationProps={navigationProps}/>
                {rows}
            </div>
        </ContainerDiv>
    )
}