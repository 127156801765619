import RoadmapElement from "../../../interfaces/RoadmapElement";
import React, {Dispatch, JSX, SetStateAction, useState} from "react";
import {Checkbox, DatePicker, InputText, TextArea} from "@fattureincloud/fic-design-system";
import {deleteElement, editElement, moveElement} from "../../../api/roadmapElements";
import Editable from "../../../interfaces/Editable";
import {DataBlock} from "../../../interfaces/DataProp";
import DialogModal from "./DialogModal";
import NavigationProps from "../../../interfaces/NavigationProps";
import arrowUp from "../../../asset/arrow_up.png"
import arrowDown from "../../../asset/arrow_down.png"
import {
    ButtonNewRight,
    CardElement,
    DivArrow,
    ImageAdd,
    TagDev
} from "../../../utils/styledComponents/styledComponents";
import {CardTextArea, CardTitle, CheckboxDiv} from "./project.style";

export default function CardData(
    {
        logged,
        data,
        edit,
        setEdit,
        select,
        setSelect,
        selectAll,
        setSelectAll,
        selecting,
        dataProp,
        navigationProps
    }: {
        logged: boolean | undefined,
        data: RoadmapElement,
        edit: Editable,
        setEdit: Dispatch<SetStateAction<Editable>>,
        select: number[],
        setSelect: Dispatch<SetStateAction<number[]>>,
        selectAll: boolean,
        setSelectAll: Dispatch<SetStateAction<boolean>>,
        selecting: boolean
        dataProp: DataBlock
        navigationProps: NavigationProps
    }): JSX.Element {

    /*const [edit, setEdit] = useState<boolean>(false)*/
    const [title, setTitle] = useState<string>(data.title)
    const [isDone, setIsDone] = useState<boolean>(data.tag === "DONE")
    const [description, setDescription] = useState<string>(data.description)
    const [date, setDate] = useState<Date | null>(data.doneDate === null ? new Date(Date()) : new Date(data.doneDate))
    const [isConfirmOpened, setIsConfirmOpened] = useState(false);


    var titleRow;
    var isDoneRow;
    var descriptionRow;
    var dateRow;
    var move;

    if (logged && edit.edit && edit.id === data.id) {
        titleRow = <>
            <InputText
                value={title}
                onChange={
                    (event) => {
                        if (event.target.value.length < 50)
                            setTitle(event.target.value)
                    }
                }/>
            <br/>
        </>;
        const tag = isDone ?
            <TagDev $done>Completato</TagDev> :
            <TagDev>In sviluppo</TagDev>
        isDoneRow = <Checkbox
            text={tag}
            value={isDone.valueOf()}
            onClick={() => {
                setIsDone(!isDone)
            }}
        />
        descriptionRow = <>
            <TextArea
                maxContentLength={512}
                initialHeight="120px"
                value={description}
                onChange={(e) => {
                    setDescription(e.target.value)
                }}
            />
            <br/>
        </>
        isDone
            ? dateRow = <div style={{marginTop:'10px'}}><DatePicker

                date={date}
                onChangeManually={
                    (e: Date | null) => setDate(e)
                }
                setDate={setDate}
            /></div>
            : dateRow = <></>
        move =
            <DivArrow>
                <ImageAdd
                    src={arrowUp}
                    alt="up"
                    onClick={()=>{
                        moveElement(data.id,true,!isDone,dataProp,navigationProps)
                    }}
                />
                <ImageAdd
                    src={arrowDown}
                    alt="down"
                    onClick={()=>{
                        moveElement(data.id,false,!isDone,dataProp,navigationProps)
                    }}
                />
            </DivArrow>

    } else {
        const tag = data.tag === "DONE" ?
            <TagDev $done>Completato</TagDev> :
            <TagDev>In sviluppo</TagDev>
        titleRow = <CardTitle>{data.title}</CardTitle>
        isDoneRow = tag
        descriptionRow = <CardTextArea children={data.description}/>
        dateRow = isDone ? <p>Completato il {new Date(data.doneDate).toLocaleDateString()}</p> : <></>
        move = <></>
    }

    return (//className={cardClass}
        <CardElement $changeBgColor={selecting} onClick={
            () => {
                if (selecting) {
                    if (select.includes(data.id)) {
                        setSelect(select.filter((element) => element !== data.id))
                    } else {
                        setSelect([...select, data.id])
                    }
                    if (selectAll) {
                        setSelectAll(false)
                    }
                }
            }
        }>
            {logged && (
                <>
                    {
                        !selecting ?
                            <>
                                <ButtonNewRight
                                    isDisabled={
                                        (title === "" || description === "") && edit.edit && edit.id === data.id
                                    }
                                    onClick={(event) => {
                                        if (edit.edit && edit.id === data.id) {
                                            setEdit({edit: false, id: -1})
                                            editElement(data.id, title, isDone, description, date, dataProp, navigationProps)
                                        } else {
                                            setTitle(data.title)
                                            setIsDone(data.tag === "DONE")
                                            setDescription(data.description)
                                            setDate(data.doneDate === null ? new Date(Date()) : new Date(data.doneDate))
                                            setEdit({
                                                edit: true,
                                                id: data.id
                                            })
                                        }
                                    }
                                    }
                                    text={edit.edit && edit.id === data.id ? "Salva" : "Modifica"}
                                />

                                <ButtonNewRight
                                    onClick={(event) => {
                                        if (edit.edit && edit.id === data.id) {
                                            setEdit({edit: false, id: -1})
                                        } else {
                                            setIsConfirmOpened(true)
                                        }
                                    }
                                    }
                                    text={edit.edit && edit.id === data.id ? "Annulla" : "Elimina"}
                                />
                            </> :
                            <CheckboxDiv>
                                <Checkbox
                                    value={
                                        selectAll ? true : select.includes(data.id)
                                    }

                                />

                            </CheckboxDiv>


                    }
                    <DialogModal
                        title="CONFERMA"
                        isOpened={isConfirmOpened}
                        onProceed={() => {
                            deleteElement([data.id], dataProp, navigationProps)
                        }
                        }
                        onClose={() => setIsConfirmOpened(false)}
                    >
                        <p>Sei sicuro di voler procedere?</p>
                    </DialogModal>
                </>
            )
            }
            {titleRow}
            {isDoneRow}
            <br/>
            {descriptionRow}
            {dateRow}
            {move}
        </CardElement>

    )
}