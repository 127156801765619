import React, {Dispatch, FC, JSX, SetStateAction, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import RoadmapElement from "../../interfaces/RoadmapElement";
import {deleteElement, filterRoadmap} from "../../api/roadmapElements";
import CardData from "./components/CardData";
import Editable from "../../interfaces/Editable";
import {DataBlock} from "../../interfaces/DataProp";
import DialogModal from "./components/DialogModal";
import CardInsert from "./components/CardInsert";
import NavigationProps from "../../interfaces/NavigationProps";
import {
    ButtonNewRight,
    CenterTitle,
    ColContainer,
    ColDiv,
    MaxDiv,
    Title
} from "../../utils/styledComponents/styledComponents";
import {ButtonsDiv, CheckboxCenter, CheckboxDiv} from "./components/project.style";


function DoneColumn({
                        logged,
                        done,
                        data,
                        edit,
                        setEdit,
                        select,
                        setSelect,
                        selectAll,
                        setSelectAll,
                        selecting,
                        setSelecting,
                        dataProp,
                        projectId,
                        navigationProps
                    }: {
    logged: boolean | undefined,
    done: boolean,
    data: RoadmapElement[],
    edit: Editable,
    setEdit: Dispatch<SetStateAction<Editable>>,
    select: number[],
    setSelect: Dispatch<SetStateAction<number[]>>,
    selectAll: boolean,
    setSelectAll: Dispatch<SetStateAction<boolean>>,
    selecting: boolean,
    setSelecting: Dispatch<SetStateAction<boolean>>,
    dataProp: DataBlock,
    projectId: number,
    navigationProps: NavigationProps
}) {
    const rows = data.map((cardData: RoadmapElement) => {

        return <CardData key={cardData.id} data={cardData} logged={logged} edit={edit} setEdit={setEdit}
                         select={select} setSelect={setSelect} selecting={selecting} selectAll={selectAll}
                         setSelectAll={setSelectAll} navigationProps={navigationProps}
                         dataProp={dataProp}></CardData>

    })

    return (
        <ColDiv>
            <h2>{done ? "Completati" : "In sviluppo"}</h2>
            {logged ? <CardInsert isDone={done} projectId={projectId}
                                  dataProp={done ? dataProp.doneProp : dataProp.devProp} setSelect={setSelect}
                                  edit={edit} selecting={selecting} setSelecting={setSelecting}
                                  navigationProps={navigationProps}/> : <></>}
            {rows}
        </ColDiv>
    )
}


const ProjectPage: FC<NavigationProps> = (navigationProps: NavigationProps): JSX.Element => {
    const [edit, setEdit] = useState<Editable>({edit: false, id: -1})
    const [selecting, setSelecting] = useState<boolean>(false)
    const [select, setSelect] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState<boolean>(false)
    const [isConfirmOpened, setIsConfirmOpened] = useState(false);
    const {state} = useLocation();

    const id = state !== null ? state.id : 1;
    const name = state !== null ? state.name : "Fatture in Cloud Web";


    const [dev, setDev] = useState<RoadmapElement[]>([])
    const [done, setDone] = useState<RoadmapElement[]>([])

    const dataProp: DataBlock = {
        devProp: {abortController: new AbortController(), id: id, setData: setDev, isDone: 0},
        doneProp: {abortController: new AbortController(), id: id, setData: setDone, isDone: 1}
    }

    useEffect(() => {

        const abortController = new AbortController();
        filterRoadmap(dataProp.devProp);
        filterRoadmap(dataProp.doneProp);

        return () => {
            abortController.abort()
        }
    }, [])
    return (
        <>
            <CenterTitle>
                <ButtonsDiv>
                    {

                        !selecting ?
                            (navigationProps.user?.logged ?
                                <ButtonNewRight $rightMargin
                                                onClick={() => setSelecting(true)}
                                                text="Seleziona"/> : <></>) :
                            <CheckboxDiv>
                                <ButtonNewRight $rightMargin
                                                isDisabled={select.length === 0}
                                                onClick={() => setIsConfirmOpened(true)}
                                                text="Elimina selezionati"/>
                                <DialogModal
                                    title="CONFERMA"
                                    isOpened={isConfirmOpened}
                                    onProceed={() => {
                                        deleteElement(select, dataProp, navigationProps)
                                        setSelecting(false)
                                    }
                                    }
                                    onClose={() => setIsConfirmOpened(false)}
                                >
                                    <p>Sei sicuro di voler procedere?</p>
                                </DialogModal>
                                <ButtonNewRight
                                    onClick={() => {
                                        setSelect([])
                                        setSelecting(false)
                                    }}
                                    text="Annulla"/>

                                <CheckboxCenter

                                    value={selectAll}
                                    onClick={() => {
                                        if (!selectAll) {
                                            var idArray: number[] = [];
                                            dev.forEach((data: RoadmapElement) => {
                                                idArray.push(data.id)
                                            })
                                            done.forEach((data: RoadmapElement) => {
                                                idArray.push(data.id)
                                            })
                                            setSelect(idArray)
                                        } else {
                                            setSelect([])
                                        }
                                        setSelectAll(!selectAll)
                                    }
                                    }
                                    text="Seleziona tutto"/>
                            </CheckboxDiv>

                    }
                </ButtonsDiv>
                <Title>{name}</Title>
            </CenterTitle>
            <MaxDiv>
                <ColContainer>
                    <>
                        <DoneColumn logged={navigationProps.user?.logged} data={dev} done={false} edit={edit}
                                    setEdit={setEdit}
                                    select={select} setSelect={setSelect} selecting={selecting}
                                    setSelecting={setSelecting}
                                    selectAll={selectAll} setSelectAll={setSelectAll}
                                    navigationProps={navigationProps}
                                    dataProp={dataProp} key={"DEVELOPMENT"} projectId={id}/>
                        <DoneColumn logged={navigationProps.user?.logged} data={done} done={true} edit={edit}
                                    setEdit={setEdit}
                                    select={select} setSelect={setSelect} selecting={selecting}
                                    setSelecting={setSelecting}
                                    selectAll={selectAll} setSelectAll={setSelectAll}
                                    navigationProps={navigationProps}
                                    dataProp={dataProp} key={"DONE"} projectId={id}/>
                    </>
                </ColContainer>
            </MaxDiv>
        </>
    )
        ;
}

export default ProjectPage;