import {Dispatch, SetStateAction} from "react";
import {httpRequest} from "../utils/network";
import {DataBlock, DataProp} from "../interfaces/DataProp";
import NavigationProps from "../interfaces/NavigationProps";
import {Toast} from "@fattureincloud/fic-design-system";

export function filterRoadmap({abortController, id, setData, limit, isDone}: {
    abortController: AbortController,
    id: number,
    setData: (data: never[]) => void,
    limit?: number,
    isDone?: number
}) {
    httpRequest({
        method: "GET",
        path: "/filterRoadmap",
        params: {
            id: id,
            limit: limit,
            isDone: isDone,
        },
        onError: () => Toast.error("Problema di caricamento delle roadmap"),
        onSuccess: (data) => {
            setData(data);
        },

        signal: abortController.signal
    })
}

export function editElement(id: number, title: string, tag: boolean, description: string, doneDate: Date | null, dataProp: DataBlock, navigationProps: NavigationProps) {
    httpRequest({
        method: "PUT",
        onError: (response) => {
            if (response.status === 401) {
                Toast.error("Sessione scaduta")
                localStorage.clear()
                navigationProps.setUser(null)
                navigationProps.navigate("/login")
            } else {
                Toast.error("Errore nella modifica, riprovare")
            }
        },
        onSuccess: () => {
            Toast.success("Modifica avvenuta con successo")
            filterRoadmap(dataProp.devProp)
            filterRoadmap(dataProp.doneProp)
        },
        path: "/editRoadmap",
        data: {
            id: id,
            title: title,
            tag: tag ? "DONE" : "DEV",
            description: description,
            doneDate: doneDate?.toISOString().slice(0, 19).replace('T', ' ')
        },
    })
}

export function moveElement(id: number, up:boolean, dev:boolean, dataProp: DataBlock, navigationProps: NavigationProps) {
    httpRequest({
        method: "GET",
        onError: (response) => {
            if (response.status === 401) {
                Toast.error("Sessione scaduta")
                localStorage.clear()
                navigationProps.setUser(null)
                navigationProps.navigate("/login")
            } else {
                Toast.error(response.data.message)
            }
        },
        onSuccess: () => {
            Toast.success("Elemento spostato")
            filterRoadmap(dev? dataProp.devProp : dataProp.doneProp)
        },
        path: "/moveRoadmap",
        params: {
            id: id,
            up: up
        },
    })
}

export function deleteElement(id: number[], dataProp: DataBlock, navigationProps: NavigationProps) {
    httpRequest({
        method: "DELETE",
        onError: (response) => {
            if (response.status === 401) {
                Toast.error("Sessione scaduta")
                localStorage.clear()
                navigationProps.setUser(null)
                navigationProps.navigate("/login")
            } else {
                Toast.error("Errore nell'eliminazione, riprovare")
            }
        },
        onSuccess: (data) => {
            filterRoadmap(dataProp.devProp)
            filterRoadmap(dataProp.doneProp)
            Toast.success("Eliminazione avvenuta con successo")
        },
        path: "/deleteRoadmap",
        data: {
            id: id
        },

    })
}

export function insertElement(title: string, tag: boolean, description: string, doneDate: Date | null, projectId: number, dataProp: DataProp, setInserting: Dispatch<SetStateAction<boolean>>, navigationProps: NavigationProps) {
    const params = tag ? {
        title: title,
        tag: tag ? "DONE" : "DEV",
        description: description,
        project_id: projectId,
        doneDate: doneDate?.toISOString().slice(0, 19).replace('T', ' ')
    } : {
        title: title,
        tag: tag ? "DONE" : "DEV",
        description: description,
        project_id: projectId,
    }

    httpRequest({
        method: "POST",
        onError: (response) => {
            if (response.status === 401) {
                Toast.error("Sessione scaduta")
                localStorage.clear()
                navigationProps.setUser(null)
                navigationProps.navigate("/login")
            } else {
                Toast.error("Errore nell'inserimento, riprovare")
            }
        },
        onSuccess: () => {
            filterRoadmap(dataProp)
            setInserting(false)
            Toast.success("Inserimento avvenuto con successo")
        },
        path: "/addRoadmap",
        data: params,

    })
}