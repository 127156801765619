import styled, {css} from "styled-components";
import {Button, Card} from "@fattureincloud/fic-design-system";

export const ColContainer = styled.div`
  display: table;
  width: 100%;
  margin: 20px 20px 20px 20px
`

export const ColDiv = styled.div<{ $right?: boolean }>`
  display: table-cell;
  width: 50%;
  max-width: 50vw;
  ${props => props.$right ? css`
            width: 70%
          ` :
          css`
            width: 30%
          `

  };
  @media only screen and (max-width: 1000px) {
    display: block;
    width: 100%;
    max-width: 100vw;
  }
`

export const TitleDiv = styled.div`
  width: 100vw;
  background-color: #e9ecef;
  padding-bottom: 20px;
`

export const CenterTitle = styled.div`
  max-width: 1500px;
  margin: auto;
  position: relative;
`

export const Title = styled.h1<{ $subtitle?: boolean }>`
  color: #1795d1;
  margin-left: 20px;
  padding-top: 10px;
  font-weight: normal;
  font-size: 50px;
`

export const SubTitle = styled.h2<{ $subtitle?: boolean }>`
  //font-size: 25px;
  margin-left: 20px;
  padding-top: 10px;
`

export const TagDev = styled.span<{ $done?: boolean }>`
  border-radius: 14px;
  padding: 4px 12px;
  font-weight: 400;
  font-size: 12px;
  background-color: #1795d1;
  border: 1px solid #1795d1;
  color: #fff;
  ${props => props.$done && css`
    background-color: #1d951f;
    border: 1px solid #1d951f;
  `}
`

export const ButtonNew = styled(Button)<{ $leftMargin?: boolean }>`
  position: unset !important;
  ${props => props.$leftMargin && css`
    margin-left: 15px;
  `};
`

export const CardElement = styled(Card)<{ $changeBgColor?: boolean, $cardImage?: boolean }>`
  margin-right: 40px;
  margin-bottom: 10px;

  ${props => props.$changeBgColor && css`
    &:hover,
    &:focus {
      background-color: rgb(250, 250, 250);
      transition: all 500ms;
    }
  `};
  ${props => props.$cardImage && css`
    text-align: center;
    
  `};
`

export const ButtonNewRight = styled(ButtonNew)<{$rightMargin?:boolean}>`
  float: right;
  margin-left: 15px;
  ${props => props.$rightMargin && css`
    margin-right: 20px;
  `};
`

export const Nav = styled.nav`
  background-color: #0D97D5;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  color: #FFFFFF;
  position: fixed;
  top: 0;
  z-index: 1;
`

export const DivNav = styled.div<{ $right?: boolean }>`
  display: inline;
  padding-left: 10px;
  
  ${props => props.$right && css`
    float: right;
    padding-right: 10px;
    
  `
  };
`

export const LinkNav = styled.a`
  text-decoration: none;
  color: #FFFFFF
`

export const DivImage = styled.div`
  height: 270px;
  width: 100%;
`
export const DivArrow = styled.div`
  height: 20px;
`

export const ImageAdd = styled.img`
  height: 100%;
  padding: 10px;
`

export const MaxDiv = styled.div`
  margin: auto;
  max-width: 1500px;
`