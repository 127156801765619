import styled from 'styled-components';
import {Card} from "@fattureincloud/fic-design-system";

export const Login = styled(Card)`
  min-width: 352px;
  width: 352px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  box-shadow: 0 0 20px 20px rgba(51, 51, 51, 0.2);
  border-radius: 8px;
  border-top: 8px solid rgb(13, 151, 213);
`
export const LoginTitle = styled.h1`
  font-weight: 500;
  text-align: center;
  color: #0d97d5;
  margin-top: 0;
  margin-bottom: 16px;
`
export const LoginError = styled.p`
  font-size: 14px;
  color: red;
`