import {Checkbox, InputText} from "@fattureincloud/fic-design-system";
import React, {useState} from "react";
import Editable from "../../../interfaces/Editable";
import AddImage from "../../../asset/add.png";
import AddImageOver from "../../../asset/addOnHover.png";
import {insertUser} from "../../../api/user";
import validateEmail from "../../../utils/validateEmail";
import validatePassword from "../../../utils/validatePassword";
import NavigationProps from "../../../interfaces/NavigationProps";
import {ButtonNewRight, DivImage, ImageAdd} from "../../../utils/styledComponents/styledComponents";
import {CardUserInsert} from "./admin.style";

export default function CardInsertUser({setData, edit, navigationProps}: {
    setData: (data: never[]) => void,
    edit: Editable,
    navigationProps: NavigationProps
}) {
    const [inserting, setInserting] = useState(false)
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [firstName, setFirstName] = useState<string>("")
    const [lastName, setLastName] = useState<string>("")
    const [isSuperuser, setIsSuperuser] = useState<boolean>(false);
    const [addImage, setAddImage] = useState(AddImage)
    const [wrongEmail, setWrongEmail] = useState<boolean>(false);
    const [wrongPassword, setWrongPassword] = useState<boolean>(false);

    return (
        <CardUserInsert $cardImage={!inserting}
              onClick={(event) => {
                  if (!inserting) {
                      setEmail("")
                      setUsername("")
                      setPassword("")
                      setFirstName("")
                      setLastName("")
                      setIsSuperuser(false)
                  }

                  setInserting(true)
              }}>

            {inserting ?
                <>

                    <InputText
                        label="Username:"
                        placeholder="Username"
                        value={username}
                        onChange={
                            (event) => {
                                if (event.target.value.length < 50)
                                    setUsername(event.target.value)
                            }
                        }/>
                    <br/>
                    <InputText
                        label="Password:"
                        placeholder="Password"
                        inputType="password"
                        status={wrongPassword ? "error" : "normal"}
                        value={password}
                        tooltip={{
                            message: "La password deve essere di almeno 8 caratteri, e contenere almeno un carattere " +
                            "maiuscolo, uno minuscolo, un numero e un carattere speciale",
                            type: "light",
                            placement: "top",
                            showAlways: false
                        }}
                        onChange={
                            (event) => {
                                setWrongPassword(false)
                                setPassword(event.target.value)
                            }
                        }/>
                    <br/>
                    <InputText
                        label="Email:"
                        placeholder="Email"
                        status={wrongEmail ? "error" : "normal"}
                        value={email}
                        onChange={(event) => {
                            setWrongEmail(false)
                            if (event.target.value.length < 255)
                                setEmail(event.target.value)
                        }}
                    />
                    <br/>

                    <InputText
                        label="Nome:"
                        placeholder="Nome"
                        value={firstName}
                        onChange={(event) => {
                            if (event.target.value.length < 30)
                                setFirstName(event.target.value)
                        }}
                    />
                    <br/>

                    <InputText
                        label="Cognome:"
                        value={lastName}
                        placeholder="Cognome"
                        onChange={(event) => {
                            if (event.target.value.length < 150)
                                setLastName(event.target.value)
                        }}
                    />
                    <br/>
                    <Checkbox
                        text={"Superuser"}
                        value={isSuperuser}
                        onClick={() => {
                            setIsSuperuser(!isSuperuser)
                        }}
                    />
                    <ButtonNewRight
                        isDisabled={
                            username === "" || email === "" || edit.edit
                        }
                        onClick={(event) => {
                            setWrongEmail(!validateEmail(email))
                            setWrongPassword(!validatePassword(password))
                            if (validateEmail(email) && validatePassword(password)) {
                                insertUser(username, password, email, firstName, lastName, isSuperuser, setData, setInserting, navigationProps)
                            }
                        }
                        }
                        text="Inserisci"

                    />
                </>
                :
                <DivImage
                     onMouseOver={() => setAddImage(AddImageOver)}
                     onMouseOut={() => setAddImage(AddImage)}>
                    <ImageAdd src={addImage} alt="add"/>
                </DivImage>

            }
        </CardUserInsert>)
}
