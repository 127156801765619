import {MouseEvent, useEffect, useRef} from "react";
import {ButtonNew} from "../../../utils/styledComponents/styledComponents";
import {Dialog} from "./project.style";
//import styled from "styled-components";

//
// const Container = styled.dialog`
//   width: 400px;
//   border-radius: 8px;
//   border: 1px solid #888;
//
//   ::backdrop {
//     background: rgba(0, 0, 0, 0.3);
//   }
// `;
//
// const Buttons = styled.div`
//  display: flex;
//  gap: 20px;
// `;

const isClickInsideRectangle = (e: MouseEvent, element: HTMLElement) => {
    const r = element.getBoundingClientRect();

    return (
        e.clientX > r.left &&
        e.clientX < r.right &&
        e.clientY > r.top &&
        e.clientY < r.bottom
    );
};

type Props = {
    title: string;
    isOpened: boolean;
    onProceed: () => void;
    onClose: () => void;
    children: React.ReactNode;
};

const DialogModal = ({
                         title,
                         isOpened,
                         onProceed,
                         onClose,
                         children,
                     }: Props) => {
    const ref = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (isOpened) {
            ref.current?.showModal();
        } else {
            ref.current?.close();
        }
    }, [isOpened]);

    const proceedAndClose = () => {
        onProceed();
        onClose();
    };

    return (
        <Dialog
            ref={ref}
            onCancel={onClose}
            onClick={(e) =>
                ref.current && !isClickInsideRectangle(e, ref.current) && onClose()
            }
        >
            <h3>{title}</h3>

            {children}
            <div>
                <ButtonNew onClick={onClose} text="Annulla" />
                <ButtonNew $leftMargin onClick={proceedAndClose} text="Procedi"/>
            </div>
        </Dialog>
    );
};


export default DialogModal;
