import styled from 'styled-components';
import ReactMarkdown from "react-markdown";
import {Checkbox} from "@fattureincloud/fic-design-system";

export const CardTitle = styled.h4`
  margin-top: 0px;
  word-wrap: break-word;
`
export const CardTextArea = styled(ReactMarkdown)`
  word-wrap: break-word;
`


export const Dialog = styled.dialog`
  width: 400px;
  border-radius: 8px;
  border: 1px solid #888;
  justify-content: space-between;
  text-align: center;
`

export const CheckboxDiv = styled.div`
  float: right;
  position: relative;
`

export const CheckboxCenter = styled(Checkbox)`
  position: absolute;
  top: 50%;
  transform: translate(-100%, -50%);
`

export const ButtonsDiv = styled.div`
  
  position: absolute;
  top: 50%;
  right: 0;
  //transform: translate(100%, -50%);
`