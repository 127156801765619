import {httpRequest} from "../utils/network";
import Project from "../interfaces/Project";
import {Toast} from "@fattureincloud/fic-design-system";

export default function showProjects(abortController:AbortController, setData:(data:Project[]) => void) {

    httpRequest({
        method: "GET",
        onError: () => Toast.error("Problema di caricamento dei progetti"),
        onSuccess: (a) => {
            setData(a);
        },
        path: "/showProject",
        signal: abortController.signal
    })

}