import {NavigateFunction} from "react-router-dom";
import React, {FC, JSX, useEffect, useState} from "react";
import '../../App.css';
import {User} from "../../interfaces/User";
import RoadmapElement from "../../interfaces/RoadmapElement";
import {filterRoadmap} from "../../api/roadmapElements";
import showProjects from "../../api/project";
import Project from "../../interfaces/Project";
import NavigationProps from "../../interfaces/NavigationProps";
import {
    ButtonNew,
    CardElement,
    CenterTitle,
    ColContainer,
    ColDiv,
    MaxDiv,
    SubTitle,
    TagDev,
    Title,
    TitleDiv
} from "../../utils/styledComponents/styledComponents";
import ReactMarkdown from "react-markdown";



function ElementRow({id}: {
    id: number
}): JSX.Element {
    const [data, setData] = useState([])
    const rows: JSX.Element[] = [];

    useEffect(() => {

        const abortController = new AbortController();

        filterRoadmap({abortController: abortController, id: id, setData: setData, limit: 5});
        return () => {
            abortController.abort()
        }
    }, [id])

    data.forEach((element: RoadmapElement) => {

        const tag = element.tag === "DONE" ?
            <TagDev $done>Completato</TagDev> :
            <TagDev>In sviluppo</TagDev>

        rows.push(
            <CardElement
                key={element.id}>
                <h4>{element.title}</h4>
                {tag}
                <ReactMarkdown children={element.description}/>
                <p>{element.tag === "DONE" ? <p>Completato il {new Date(element.doneDate).toLocaleDateString()}</p> : <></>}</p>
            </CardElement>
        );

    });
    if (data.length === 0) {
        rows.push(
            <h4 key={Math.random()}>'Il progetto non ha ancora una roadmap!'</h4>
        )
    }
    return (
        <>{rows}</>
    );

}


const handleSubmit = (navigate: NavigateFunction, id: number, name: string/*, e:
                React.FormEvent<HTMLFormElement>*/) => {
    //e.preventDefault();
    navigate("/project", {state: {id: id, name: name}});
}


function ProjectTable({navigate, user}: { navigate: NavigateFunction, user: User | null }): JSX.Element {
    const [data, setData] = useState<Project[]>([])
    const rows: JSX.Element[] = [];
    useEffect(() => {

        const abortController = new AbortController();
        showProjects(abortController, setData)

        return () => {
            abortController.abort()
        }
    }, [])
    data.forEach((project: Project) => {
        rows.push(
            <ColContainer key={project.id}>

                <ColDiv $right={false}>
                    <h1>{project.name}</h1>
                    <img height="100px" alt="logo"
                         src={require("../../asset/" + project.name.replace(/ /g, "_").toLowerCase() + ".png")}/>
                    <br/><br/>
                    <ButtonNew text={user?.logged?"Modifica elementi":"Storico"}
                            onClick={() => handleSubmit(navigate, project.id, project.name)}></ButtonNew>
                    <br/><br/>
                </ColDiv>
                <ColDiv $right>

                    <ElementRow id={project.id}/>

                </ColDiv>
            </ColContainer>
        );
    });

    return (
        <MaxDiv>
            {rows}
        </MaxDiv>
    );
}


const Home: FC<NavigationProps> = (props: NavigationProps): JSX.Element => {
    return (
        <div>
            <TitleDiv>
                <CenterTitle>
                    <Title>Fatture in Cloud Roadmap</Title>
                    <SubTitle>Da qui puoi scoprire a cosa stiamo lavorando!</SubTitle>
                </CenterTitle>
            </TitleDiv>
            <ProjectTable navigate={props.navigate} user={props.user}/>
        </div>);
}

export default Home;