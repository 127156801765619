import React, {useState} from 'react';
import './App.css';
import LoginTable from "./modules/Login/login";
import {User} from "./interfaces/User";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Home from "./modules/Home/home";
import ProtectedRoute, {ProtectedRouteProps} from "./utils/ProtectedRoute";
import Admin from "./modules/Admin/admin";
import Project from "./modules/Projects/project";
import {GetUser} from "./utils/GetUser";
import {logout} from "./api/login";
import {DivNav, LinkNav, Nav} from "./utils/styledComponents/styledComponents";
import { Toast } from '@fattureincloud/fic-design-system'



export default function App() {
    const [user, setUser] = useState<User | null>(null);
    const navigate = useNavigate();
    // localStorage.clear();
    const userFile = GetUser(setUser);
    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        isAuthenticated: userFile !== null && userFile.logged && userFile.isSuperuser,
        authenticationPath: '/login',
    };

    return (
        <>
            <Nav>
                <DivNav>
                    <LinkNav href="/">Home</LinkNav></DivNav>
                {
                    user?.logged ? (
                        <>
                            <DivNav $right onClick={() => {
                                logout(setUser)
                            }}>Logout
                            </DivNav>
                            {
                                user.isSuperuser ?
                                    <DivNav $right>
                                        <LinkNav href="/admin">Gestione utenti</LinkNav>
                                    </DivNav> : <></>
                            }
                        </>
                    ) : <></>
                }
            </Nav>
            <Toast.Container position={'bottom-right'}/>
            <Routes>
                <Route path="/" element={<Home navigate={navigate} user={user} setUser={setUser}/>}/>
                <Route path="/project" element={<Project navigate={navigate} user={user} setUser={setUser}/>}/>
                <Route path="/login" element={<LoginTable setUser={setUser} user={user} navigate={navigate}/>}/>
                <Route path="/admin" element={<ProtectedRoute {...defaultProtectedRouteProps}
                                                              outlet={<Admin user={user} navigate={navigate}
                                                                             setUser={setUser}/>}/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </>
    );
};
