import axios, {AxiosError, AxiosRequestHeaders, AxiosResponse, Method} from 'axios'

export interface ErrorInterface {
  message: string
  validation_result: string
}

export const httpRequest = ({
  method,
  path,
  data = {},
  headers,
  onSuccess,
  onError,
  getResponse,
  responseType,
  params = {},
  signal
}: {
  signal?:AbortSignal
  method?: Method
  path?: string
  data?: Record<string, unknown> | FormData
  params?: Record<string, unknown>
  headers?: AxiosRequestHeaders
  onSuccess?: (data: any) => void
  onError?: (response: AxiosResponse) => void
  getResponse?: (data: AxiosResponse) => void
  responseType?:
  | 'arraybuffer'
  | 'blob'
  | 'document'
  | 'json'
  | 'text'
  | 'stream'
  | undefined
}): void => {
  axios.defaults.withCredentials = true
  axios({

    method:method,
    url: String(process.env.REACT_APP_BE_HOST_API) + String(path),
    params: params,
    data: data,
    responseType: responseType,
    withCredentials: true,
    headers:headers,
    signal:signal
  })
    .then((response) => {
      if (onSuccess) onSuccess(response.data)
      if (getResponse) getResponse(response)
    })
    .catch(async (res: AxiosError<{ error: ErrorInterface }>) => {

      if (onError && res.response?.status === 401) {
        console.log('unauth')
        onError(res.response)
        return;
      }

      if (onError && res.response?.data) {
        console.log('Error')
        onError(res.response)
        return;
      }
    })
}



