import {User} from "../interfaces/User";
import {useEffect} from "react";

export function GetUser(setUser: (user: (User | null)) => void): User | null {
    const id = localStorage.getItem('id');
    const username = localStorage.getItem('username');
    const logged = localStorage.getItem('logged');
    const isSuperuser=localStorage.getItem('isSuperuser')
    useEffect(() => {
        if (id !== null && username !== null && logged !== null && isSuperuser) {

            setUser({
                id: parseInt(id),
                username: username,
                logged: Boolean(Number(logged)),
                isSuperuser: Boolean(Number(isSuperuser))
            });

        }
    }, [id, username, logged, isSuperuser, setUser])

    if (id !== null && username !== null && logged !== null && isSuperuser !== null) {
        return {
            id: parseInt(id),
            username: username,
            logged: Boolean(Number(logged)),
            isSuperuser: Boolean(Number(isSuperuser))
        }
    } else return null
}