import {DatePicker, InputText, TextArea} from "@fattureincloud/fic-design-system";
import React, {Dispatch, SetStateAction, useState} from "react";
import {insertElement} from "../../../api/roadmapElements";
import {DataProp} from "../../../interfaces/DataProp";
import Editable from "../../../interfaces/Editable";
import AddImage from "../../../asset/add.png";
import AddImageOver from "../../../asset/addOnHover.png";
import NavigationProps from "../../../interfaces/NavigationProps";
import {ButtonNewRight, CardElement, DivImage, ImageAdd} from "../../../utils/styledComponents/styledComponents";

export default function CardInsert({isDone, projectId, dataProp, edit, selecting, setSelecting, setSelect, navigationProps}: {
    isDone: boolean,
    projectId: number,
    dataProp: DataProp,
    edit: Editable,
    selecting: boolean,
    setSelecting: Dispatch<SetStateAction<boolean>>
    setSelect: Dispatch<SetStateAction<number[]>>
    navigationProps: NavigationProps
}) {
    const [inserting, setInserting] = useState(false)
    const [title, setTitle] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [date, setDate] = useState<Date | null>(new Date(Date()))
    const [addImage, setAddImage] = useState(AddImage)

    return (
        <CardElement $cardImage
              onClick={(event) => {
                  if (!inserting) {
                      setTitle("")
                      setDescription("")
                      setDate(new Date(Date()))
                  }

                  setSelecting(false);
                  setSelect([])
                  setInserting(true)
              }}
        >

            {inserting && !selecting ?
                <>
                    <InputText
                        value={title}
                        disabled={
                            edit.edit
                        }
                        placeholder="Title"
                        onChange={
                            (event) => {
                                if (event.target.value.length < 75)
                                    setTitle(event.target.value)
                            }
                        }/>

                    <br/>
                    <TextArea
                        disabled={
                            edit.edit
                        }
                        maxContentLength={512}
                        initialHeight="120px"
                        value={description}
                        placeholder="Description"
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                    />
                    <br/>
                    {isDone
                        ? <DatePicker
                            disabled={
                                edit.edit
                            }
                            date={date}
                            onChangeManually={
                                (e) => setDate(e)
                            }
                            setDate={setDate}
                        />
                        : <></>}
                    <br/>
                    <ButtonNewRight
                        isDisabled={
                            title === "" || description === "" || edit.edit
                        }
                        onClick={(event) => {
                            insertElement(title, isDone, description, date, projectId, dataProp, setInserting, navigationProps)
                        }
                        }
                        text="Inserisci"

                    />
                </>
                :
                <DivImage
                     onMouseOver={() => setAddImage(AddImageOver)}
                     onMouseOut={() => setAddImage(AddImage)}>
                    <ImageAdd src={addImage} alt="add"/>
                </DivImage>

            }
        </CardElement>)
}