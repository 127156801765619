import {httpRequest} from "../utils/network";
import {Dispatch, SetStateAction} from "react";
import NavigationProps from "../interfaces/NavigationProps";
import {Toast} from "@fattureincloud/fic-design-system";

export function showUsers(
    abortController: AbortController,
    setData: (data: never[]) => void,
    navigationProps: NavigationProps
) {

    httpRequest({
        method: "GET",
        path: "/showUser",
        onError: (response) => {
            if (response.status === 401) {
                if (Boolean(Number(localStorage.getItem('isSuperuser')))) {
                    Toast.error("Sessione scaduta")
                    localStorage.clear()
                    navigationProps.setUser(null)
                    navigationProps.navigate("/login")
                } else {
                    Toast.error("Utente non autorizzato")
                    navigationProps.navigate("/")
                }
            } else {
                Toast.error("Errore di visualizzazione")
            }
        },
        onSuccess: (data) => {
            setData(data)
        },

        signal: abortController.signal
    })
}

export function editUser(id: number, username: string, firstName: string, lastName: string, email: string, isSuperuser: boolean, setData: (data: never[]) => void, navigationProps: NavigationProps) {
    httpRequest({
        method: "PUT",
        onError: (response) => {
            if (response.status === 401) {
                if (navigationProps.user?.isSuperuser) {
                    Toast.error("Sessione scaduta")
                    localStorage.clear()
                    navigationProps.setUser(null)
                    navigationProps.navigate("/login")
                } else {
                    Toast.error("Utente non autorizzato")
                    navigationProps.navigate("/")
                }
            } else {
                Toast.error("Errore nella modifica, riprovare")
            }
        },
        onSuccess: () => {
            showUsers(new AbortController(), setData, navigationProps)
            Toast.success("Modifica avvenuta con successo")
        },
        path: "/editUser",
        data: {
            id: id,
            username: username,
            first_name: firstName,
            last_name: lastName,
            email: email,
            is_superuser: isSuperuser ? 1 : 0
        },
    })
}

export function deleteUser(id: number, setData: (data: never[]) => void, navigationProps: NavigationProps) {
    httpRequest({
        method: "DELETE",
        onError: (response) => {
            if (response.status === 401) {
                if (navigationProps.user?.isSuperuser) {
                    Toast.error("Sessione scaduta")
                    localStorage.clear()
                    navigationProps.setUser(null)
                    navigationProps.navigate("/login")
                } else {
                    Toast.error("Utente non autorizzato")
                    navigationProps.navigate("/")
                }
            } else {
                Toast.error("Errore nell'eliminazione, riprovare")
            }
        },
        onSuccess: (data) => {
            showUsers(new AbortController(), setData, navigationProps)
            Toast.success("Eliminazione avvenuta con successo")
        },
        path: "/deleteUser",
        data: {
            id: id
        },

    })
}

export function insertUser(username: string, password: string, email: string, firstName: string, lastName: string, isSuperUser: boolean,
                           setData: (data: never[]) => void, setInserting: Dispatch<SetStateAction<boolean>>, navigationProps: NavigationProps) {
    httpRequest({
        method: "POST",
        onError: (response) => {
            if (response.status === 401) {
                if (navigationProps.user?.isSuperuser) {
                    Toast.error("Sessione scaduta")
                    localStorage.clear()
                    navigationProps.setUser(null)
                    navigationProps.navigate("/login")
                } else {
                    Toast.error("Utente non autorizzato")
                    navigationProps.navigate("/")
                }
            } else {
                Toast.error(response.data.message)
            }
        },
        onSuccess: () => {
            showUsers(new AbortController(), setData, navigationProps)
            setInserting(false)
            Toast.success("Inserimento avvenuto con successo")
        },
        path: "/addUser",
        data: {
            username: username,
            password: password,
            email: email,
            first_name: firstName,
            last_name: lastName,
            is_superuser: isSuperUser
        }


    })
}