import styled, {css} from 'styled-components';
import {Card} from "@fattureincloud/fic-design-system";

export const ContainerDiv = styled.div`
  margin-right:4vw;
  margin-left:4vw;
`

export const CardUser = styled(Card)`
  min-height: 180px;
  margin-bottom: 10px;
`
export const CardUserInsert = styled(CardUser)<{$cardImage?:boolean}>`
  height: 420px;
  ${props => props.$cardImage && css`
    text-align: center;
    height: 250px;
  `};
`